import React from "react";
import { Button, Image, Row, Col, Container } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/tax.jpg";
import FBR_Pakistan_logo from '../../img/FBR_Pakistan_logo.png';

const FBRIntegration = () => {
  return (
    <>
      <Header
        backgroundImage={headerbg}
        title="FBR Integration"
        content="Our seamless POS-FBR integration makes tax compliance easier than ever before."
        button="Get Free Trail"
      />
        <section>
          <Container>
            <Row className="align-items-center text-center">
              <Col xs={12} sm={12}>
                <div className="main-heading">
                  <h1>
                  Point of Sales (POS)<span>FBR Integration</span>
                  </h1>
                  <h5>Online POS – FBR integration in less than an hour</h5>
                  <div className="bottom-border">
                    <span className="blue"></span>
                    <span className="yellow"></span>
                  </div>
                </div>
                <p>
                Another Significant Benefit of Jadeed Munshi Online POS is FBR System Integration for customers. It allows them to readily verify whether or not tax has been deposited with the FBR. The invoice numbers and unique QR Codes on the sales receipts created by the Jadeed Munshi POS Software can be validated by clients using “The Federal Board Of Revenue’s Tax Asaan Application (FBR).”
                </p>
                <div className="fbr-logo"></div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} sm={12} md={5}>
                <Image src={FBR_Pakistan_logo} />
              </Col>
            </Row>
          </Container>
        </section>
      
    </>
  );
};

export default FBRIntegration;

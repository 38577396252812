import React from "react";
import { Button, Image, Row, Col, Container } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/pos.jpg";
import posimg from '../../img/pos.png';
import posDiscountsOffers from '../../img/posDiscountsOffers.png';
import posLoyaltyProgram from '../../img/posLoyaltyProgram.png';
import posTools from '../../img/posTools.jpg';

const POS = () => {
  return (
    <>
      <Header
        backgroundImage={headerbg}
        title="Point of Sale (POS) Software"
        content="A Convenient POS software System that works Online"
        button="Get Free Trail"
      />
        <section>
          <Container>
            <Row className="align-items-center text-center">
              <Col xs={12} sm={12}>
                <div className="main-heading">
                  <h1>
                  Intuitive <span>Point of Sale Management</span>
                  </h1>
                  <h5>–Web-based POS Solution</h5>
                  <div className="bottom-border">
                    <span className="blue"></span>
                    <span className="yellow"></span>
                  </div>
                </div>
                <p>
                The Jadeed Munshi POS system operates both online. Our cloud-based software enables you to smoothly run the POS system even without an internet connection, ensuring uninterrupted business operations in case of power outages or connectivity issues. You no longer have to remain constantly connected to the internet, as our system ensures seamless functionality in various situations.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} sm={6}>
                <h4>Features</h4>
                <ul>
                  <li>Works with diverse hardware setups, featuring a user-friendly interface.</li>
                  <li>Handle card, cash, and online payments effortlessly.</li>
                  <li>Synchronize inventory data upon establishing an online connection.</li>
                  <li>Oversee sales and checkout counters efficiently.</li>
                  <li>Automatically synchronize all payments seamlessly upon internet restoration.</li>
                </ul>
              </Col>
              <Col xs={12} sm={6}><Image src={posimg} /></Col>
            </Row>
          </Container>
        </section>
      <section>
      <Container>
          <Row className="align-items-center">
            <Col xs={12} sm={5}>
              <span><Image src={posDiscountsOffers}/></span>
            </Col>
            <Col xs={12} sm={6} md={{ span: 6, offset: 1 }}>
              <div className="main-heading main-heading-left">
                <h1>
                Establish<span>discounts and offers</span> through Scheme Management
                </h1>
              </div>
              <p>
              Elevate your business with the scheme management feature of Jadeed Munshi POS software. Implementing effective sales strategies can significantly enhance the growth of your business. Customize appealing schemes using our POS to draw in more customers and drive success.
              </p>
              <p>
              Drive business promotion and boost sales by adjusting the features within our POS system's dashboard.
              </p>
              <ul>
                <li>Implement promotional discounts.</li>
                <li>Provide discounts tailored to specific products.</li>
                <li>Roll out offers specific to holidays.</li>
                <li>Provide coupons and vouchers for special promotions.</li>
                <li>Implement buy one, get one free promotions.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="align-items-center">
          <Col xs={12} sm={6} md={7}>
              <div className="main-heading main-heading-left">
                <h1>
                Enhance customer <span>satisfaction through a Loyalty Program,</span> providing added value to your customers.
                </h1>
              </div>
              <p>
              Enhance customer retention and attract new clients with loyalty programs using the integrated feature in Splendid Accounts POS system. Easily enroll customers in the loyalty program, and our POS system will automatically update their profiles with point-based rewards.
              </p>
              <ul>
                <li>Customers who enroll will accrue points based on their spending.</li>
                <li>Automatic issuance of bonuses and discounts for customers.</li>
                <li>The customer database is regularly updated with their purchase history.</li>
                <li>The history of reward points can be saved and updated for customers.</li>
              </ul>
            </Col>
            <Col xs={12} sm={5} className="position-relative">
              <span><Image src={posLoyaltyProgram}/></span>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
      <Container>
          <Row className="align-items-center">
            <Col className="aboutimg" xs={12} sm={5}>
              <Image src={headerbg}/>
            </Col>
            <Col xs={12} sm={6} md={{ span: 6, offset: 1 }}>
              <div className="main-heading main-heading-left">
                <h1>
                Why choose<span>Jadeed Munshi POS</span> point of sale software system
                </h1>
              </div>
              <p>Our point of sale software is user-friendly and can be tailored to meet your specific business needs. Opting for the Splendid Accounts POS system will contribute to the success of your business because:</p>
             <ul>
                <li>Universal compatibility across devices, encompassing laptops, desktop computers, and POS system devices.</li>
                <li>Configurable to meet the specific needs of individual businesses.</li>
                <li>Intuitive and user-friendly interface for ease of understanding.</li>
                <li>Intuitive and user-friendly software.</li>
                <li>Suitable for streamlining operations across multiple locations.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
      <Container>
          <Row className="align-items-center">
            <Col xs={12} sm={6} md={7}>
              <div className="main-heading main-heading-left">
                <h1>
                POS <span>Software</span> Tools
                </h1>
              </div>
              <p>The Jadeed Munshi point of sale software provides a range of tools to assist in creating a robust and profitable accounting system.</p>
             <ul>
                <li>Inventory management.</li>
                <li>Barcode scanners.</li>
                <li>Invoice customization.</li>
                <li>Record of clients.</li>
                <li>Customer Management.</li>
                <li>Multiple Payment Methods</li>
                <li>Remote accessibility.</li>
                <li>Email alerts.</li>
              </ul>
            </Col>
            <Col className="aboutimg" xs={12} sm={5}>
              <Image src={posTools}/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default POS;

import { Row, Col, Container, Card, Image } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from '../img/clients.jpg'
import rehmatfood from "../img/clients/rehmatfood.jpg";
import barkatfood from "../img/clients/barkatfood.jpg";
import idealprinter from "../img/clients/idealprinter.jpg";
import finad from "../img/clients/finad.jpg";
import hondabikemoters from "../img/clients/hondabikemoters.jpg";
import alipackages from "../img/clients/alipackages.jpg";
import hasampaper from "../img/clients/hasampaper.jpg";
import paktentage from "../img/clients/paktentage.jpg";
import citypark from "../img/clients/citypark.jpg";
import chanabpark from "../img/clients/chanabpark.jpg";
import humerafabrics from "../img/clients/humerafabrics.jpg";
import zamanpvc from "../img/clients/zamanpvc.jpg";
import sifcoengineering from "../img/clients/sifcoengineering.jpg";

const clientImages = [
  rehmatfood,
  barkatfood,
  idealprinter,
  finad,
  hondabikemoters,
  alipackages,
  hasampaper,
  paktentage,
  citypark,
  chanabpark,
  humerafabrics,
  zamanpvc,
  sifcoengineering,];

function Clients() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Clients"/>
      <section className="clients">
        <Container>
            <Row>
            <div className="main-heading text-center">
                <h1>Our Valuable <span>Clients</span></h1>
                <div className="bottom-border">
                <span className="blue"></span>
                <span className="yellow"></span>
                </div>
            </div>
            </Row>
            <Row className="justify-content-center">
                {clientImages.map((image, index) => (
                <Col key={index} xs={6} md={3} className="mt-4">
                    <Card>
                    <Card.Body className="text-center">
                        <Image src={image} fluid />
                    </Card.Body>
                    </Card>
                </Col>
                ))}
            </Row>
          </Container>
      </section>
    </>
  );
}

export default Clients;

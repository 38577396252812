import React from 'react'
import Header from "../InnerHeader";
import headerbg from '../../img/distributionWholesale.jpg'
import BusinessType from '../../components/BusinessTypes/BusinessTypes';
const DistributionWholesale = () => {
  const content = (
    <b className="text-warning">
      Jadeed Munshi's
    </b>
  );
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Distribution Wholesale" 
        tagline="Full-Featured Point of Sale Software for Bookstores" 
        content={
          <span>{content} Online Accounting Software can fully automate any Wholesale & Distribution structure. Tailor your sales to customer needs, scale comfortably, and make informed decisions with real-time reporting.
          </span>
        }
      />
      <BusinessType/>
    </>
  );
};



export default DistributionWholesale
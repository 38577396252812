import React from 'react'
import Header from "./InnerHeader";
import headerbg from '../img/contact.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from './ContactForm';
import GoogleMaps from "./GoogleMap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faMapLocation,
  faMapPin,
  faEnvelope,
  faHeadphonesAlt,
} from "@fortawesome/free-solid-svg-icons";
const Contact = () => {
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Contact Us" 
      />
      <section>
        <Container>
            <Row className='justify-content-center text-center'>
                <Col sm={12} md={12} className="main-heading text-center">
                    <h1>Get in <span>Touch</span></h1>
                    <div className="bottom-border">
                        <span className="blue"></span>
                        <span className="yellow"></span>
                    </div>
                </Col>
                <Col sm={12} md={7}>
                    <p>We're delighted that you've reached out to us. Your thoughts, questions, and feedback are important to us, and we're here to assist you in any way we can.</p>
                </Col>
            </Row>
            <Row className='mt-4 contact-form-main justify-content-center'>
                <Col sm={12} md={5}>
                    <h5>Quick Response to Your Query</h5>
                    <ul>
                    <li>
                      <FontAwesomeIcon className="icon" icon={faPhone} />{" "}
                      <span>
                        +92 32 60667666, <br></br>+92 300 8639171
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon className="icon" icon={faEnvelope} />{" "}
                      <span>info@jadeedmunshi.com</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faHeadphonesAlt}
                      />{" "}
                      <span>sales@jadeedmunshi.com</span>
                    </li>
                    <li>
                      <FontAwesomeIcon className="icon" icon={faMapLocation} />{" "}
                      <span>327-F, Shah Ruken-E-Alam Colony, Multan</span>
                    </li>
                  </ul>
                </Col>
                <Col sm={12} md={7}>
                  <ContactForm/>
                </Col>
            </Row>
        </Container>
      </section>
      <GoogleMaps></GoogleMaps>
    </>
  )
}

export default Contact

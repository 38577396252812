import React from 'react'
import Header from "../InnerHeader";
import headerbg from '../../img/electronicsAppliances.jpg'
import BusinessType from '../../components/BusinessTypes/BusinessTypes';
const ElectronicsAppliances = () => {
  const content = (
    <b className="text-warning">
      Jadeed Munshi's
    </b>
  );
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Electronics Appliances" 
        tagline="Boost Your Electronics & Appliances Business with Us" 
        content={
          <span>Revolutionize your Electronics & Appliances business with {content} Software, offering complete automation for seamless operations. Cater to customer needs, scale effortlessly, and make informed decisions with real-time reporting.
          </span>
        }
      />
      <BusinessType/>
    </>
  );
};



export default ElectronicsAppliances

import React from 'react';

function GoogleMaps() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.612613372046!2d71.50429057616516!3d30.191058111608196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b335e5fbcbc77%3A0x8de1ccdfe6804259!2sSOFTAGICS!5e0!3m2!1sen!2s!4v1696402622281!5m2!1sen!2s"
      width="100%"
      height="250"
      style={{ border: '0' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

export default GoogleMaps;

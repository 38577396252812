import React from 'react'
import {Button, Image, Col, Row, Container } from 'react-bootstrap';
import inventory from '../../img/inventory.png';
import smoothPOS from '../../img/smoothPOS.png';
import customerProfiles from '../../img/customerProfiles.png';
import taxManagement from '../../img/taxManagement.png';
import warehouse from '../../img/warehouse.png';
import tailoredProduct from '../../img/tailoredProduct.png';
import multiStore from '../../img/multiStore.png';
import salesReportsAnalytics from '../../img/salesReportsAnalytics.png';
import inventoryManagement from '../../img/inventoryManagement.png';
import integrationWooCommerceShopify from '../../img/integrationWooCommerceShopify.png';
import financialBookkeeping from '../../img/financialBookkeeping.png';
import automatedAccountingEntryPosting from '../../img/automatedAccountingEntryPosting.png';

const sections = [
  {
    heading: 'Streamlined Inventory Control',
    description: 'Manage your book inventory effortlessly with our robust inventory management system. Add new books, update quantities, and receive timely alerts when stock levels are running low, ensuring you stay on top of your inventory needs.',
    imageSrc: inventory,
  },
  {
    heading: "Smooth Point-of-Sale (POS) Transactions",
    description: 'Effortlessly process sales with our user-friendly interface. In just a few clicks, your cashiers can complete transactions swiftly and accurately, ensuring a seamless and efficient checkout experience.',
    imageSrc: smoothPOS,
  },
  {
    heading: 'Tailored Product Categories',
    description: "Arrange your books into personalized categories for convenient browsing. Whether it's fiction, non-fiction, romance, or sci-fi, our software facilitates easy navigation, helping customers effortlessly find what they're looking for.",
    imageSrc: tailoredProduct,
  },
  {
    heading: 'Customer Profiles ',
    description: "Foster enduring connections with your customers through our integrated customer profiling system. Monitor purchase history, preferences, and contact information to provide personalized recommendations and exclusive promotions.",
    imageSrc: customerProfiles,
  },
  {
    heading: 'Sales Reports and Analytics',
    description: "Gain access to valuable insights into your bookstore's performance. Analyze sales trends, identify popular authors, and pinpoint peak shopping hours to optimize your book selection and refine your marketing strategies.",
    imageSrc: salesReportsAnalytics,
  },
  {
    heading: 'Multi-Store Integration',
    description: "For businesses with multiple bookstore locations, our software offers seamless synchronization and centralized management. This makes it easy to efficiently oversee inventory and sales across all your stores.",
    imageSrc: multiStore,
  },
  {
    heading: 'Warehouse Management',
    description: "Optimize your bookstore's warehouse operations with our all-encompassing warehouse management system. Streamline inventory movements, effectively manage stock locations, and enhance order fulfillment processes with ease.",
    imageSrc: warehouse,
  },
  {
    heading: 'Inventory Management',
    description: "Maintain control over your book inventory using our robust inventory management features. Effortlessly add new books, monitor quantities, and efficiently manage stock levels across multiple locations.",
    imageSrc: inventoryManagement,
  },
  {
    heading: 'Automated Accounting Entry Posting',
    description: "Streamline your financial processes with our automated accounting entry posting feature. Automatically generate accurate and error-free accounting entries, saving you time and minimizing manual data entry errors.",
    imageSrc: automatedAccountingEntryPosting,
  },
  {
    heading: 'Financial Bookkeeping',
    description: "Attain full visibility into your bookstore's financial health using our integrated financial accounting module. Monitor revenue, expenses, and profit margins, effortlessly generating comprehensive financial reports for a thorough understanding of your financial status.",
    imageSrc: financialBookkeeping,
  },
  {
    heading: 'Tax Management',
    description: "Effortlessly manage tax calculations and reporting with our software. Benefit from robust tax management capabilities, enabling you to configure tax rates, automate calculations, and generate reports for compliance seamlessly.",
    imageSrc: taxManagement,
  },
  {
    heading: 'Seamless Integration with WooCommerce and Shopify',
    description: "Effortlessly integrate your online bookstore with popular e-commerce platforms such as WooCommerce and Shopify. Experience synchronized inventory, streamlined order processing, and unified sales data across both your physical and online stores.",
    imageSrc: integrationWooCommerceShopify,
  },
  {
    heading: 'Fasten Your Sale Process',
    description: "Jadeed Munshi sales process is exceptionally fast. The sales screen has been meticulously designed with a focus on highly bustling retail stores and wholesalers who may not use software due to time constraints in their sales process. Jadeed Munshi Software is poised to elevate and automate their sales process, reducing the time required from minutes to mere seconds.",
    imageSrc: inventory,
  },
  
  // Add more objects as needed for additional sections
];
const BusinessTypes = () => {
  return (
    <>
      {sections.map((section, index) => (
        <section className='sectionData' key={index}>
          <Container>
            <Row className="align-items-center position-relative">
              <Col className='info' xs={12} sm={6} md={6}>
                <div className="main-heading main-heading-left">
                  <h1>
                    {section.heading.split(' ').map((word, wordIndex) => (
                      <React.Fragment key={wordIndex}>
                        {wordIndex > 0 && ' '}
                        {wordIndex === 1 ? (
                          <span>{word}</span>
                        ) : (
                          word
                        )}
                      </React.Fragment>
                    ))}
                  </h1>
                  <div className="bottom-border">
                    <span className="blue"></span>
                    <span className="yellow"></span>
                  </div>
                </div>
                <p>{section.description}</p>
              </Col>
              <Col xs={12} sm={6} md={6} className={`position-relative ${index % 2 === 0 ? 'order-sm-2' : ''}`}>
                {/* Use 'order-sm-2' to swap the order for even-indexed sections */}
                <span className="aboutimg">
                  <Image src={section.imageSrc} />
                </span>
              </Col>
            </Row>
          </Container>
        </section>
      ))};
      <section>
        <Container>
          <Row className='text-center'>
          <Col><div className="main-heading text-center">
              <h1>Optimizing your sales strategy for both in-store and <span>online channels is the most straightforward approach</span> to broaden your market presence.</h1>
              <div className="bottom-border">
                <span className="blue"></span>
                <span className="yellow"></span>
              </div>
            </div>
            <Button variant="warning">Contact Us Now</Button></Col>
          </Row>
        </Container>
      </section>
    </>
  );
};



export default BusinessTypes
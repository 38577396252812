import React, { useState, useEffect } from 'react';
import { Card, Image, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import aboutimg from '../img/aboutimg.jpg';
const TestimonialsSlide = () => {
  const testimonials = [
    {
      id: 1,
      text: 'The point of sale software significantly benefits our seasonal business by streamlining checkout, enhancing customer loyalty through a integrated program, and providing valuable analytics for future planning, contributing to our overall success.',
      author: 'Sifco',
      thumb: aboutimg, // Use the image source here
    },
    {
      id: 2,
      text: 'Implemented point of sale software in our boutique, reducing errors, boosting productivity, and enhancing customer relationships. The cloud-based system offers remote business management, providing peace of mind. Highly recommended for retailers aiming to elevate customer experience.',
      author: 'Rehmat Foods',
      thumb: aboutimg, // Use the image source here
    },
    {
      id: 1,
      text: "Integration of cutting-edge industry-specific technology sparked remarkable evolution in our business. Exceeded expectations with efficiency gains, cost savings, and tailored solutions, empowering our team for innovation and growth. A strategic partner, not just a tool, pivotal to our success. Highly recommend for staying ahead in today's competitive landscape.",
      author: 'Ali Packages',
      thumb: aboutimg, // Use the image source here
    },
    {
      id: 2,
      text: "Implementing point of sale software in our amusement park has been a game-changer. The intuitive interface makes it easy for our staff to process transactions quickly, reducing wait times for our visitors. The flexible pricing and promotion features have allowed us to create dynamic offers, boosting sales during special events. We've received positive feedback from both our team and park guests, praising the software for its reliability and user-friendly design.",
      author: 'City Park',
      thumb: aboutimg, // Use the image source here
    },
    // Add more testimonials as needed
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Auto-scroll to the next testimonial
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000); // Change the interval (in milliseconds) as needed

    return () => {
      // Cleanup the interval on component unmount
      clearInterval(interval);
    };
  }, [testimonials.length]);

  return (
    <div className="testimonials-slide">
      <Card className='testimonial'>
        <Card.Body>
          <h1><FontAwesomeIcon icon={faQuoteLeft} /></h1>
          <p>{testimonials[currentTestimonial].text}</p>
          <div className='author-section'>
            <Image className='author-thumbnail' src={testimonials[currentTestimonial].thumb} roundedCircle alt="thumb" />
            <p>{testimonials[currentTestimonial].author}</p>
          </div>
        </Card.Body>
      </Card>
      <div className="controls">
        <Button  variant="primary" onClick={prevTestimonial}><FontAwesomeIcon icon={faAngleLeft} /></Button >
        <Button  variant="primary" onClick={nextTestimonial}><FontAwesomeIcon icon={faAngleRight} /></Button >
      </div>
    </div>
  );
};

export default TestimonialsSlide;

import React from 'react'
import Header from "../InnerHeader";
import headerbg from '../../img/carmentsFashion.jpg'
import BusinessType from '../../components/BusinessTypes/BusinessTypes';
const GarmentsFashion = () => {
  const content = (
    <b className="text-warning">
      Jadeed Munshi's
    </b>
  );
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Garments Stores Software" 
        tagline="Fashion Store Software Manages Inventory to Accounting." 
        content={
          <span>{content} is designed to fully automate any type of Garments & Fashion Business. Tailor your sales to customer needs, scale effortlessly, and make informed decisions with real-time reporting.
          </span>
        }
      />
      <BusinessType/>
    </>
  );
};

export default GarmentsFashion

import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from '../img/contact.jpg'
const accordionData = [
  {
    key: '0',
    header: 'Is it really free?',
    body: "Absolutely free for the first 14 days!",
  },
  {
    key: '1',
    header: 'How do I backup my data?',
    body: 'Ensure the security of your information and maintain seamless business operations with easy, one-click daily backups. For additional guidance, feel free to reach out to us.',
  },
  {
    key: '3',
    header: 'How do I restore my data from a backup?',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ...',
  },
  {
    key: '4',
    header: 'How do I sell on credit?',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ...',
  },
  {
    key: '5',
    header: 'How do I print labels?',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ...',
  },
  {
    key: '6',
    header: 'How do I change the printing template?',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ...',
  },
  {
    key: '7',
    header: 'How do I print reports?',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ...',
  },
  // Add more items as needed
];
const HelpCenter = () => {
  return (
    <>
      <Header backgroundImage={headerbg} title="Help Center"/>
      <section>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <Accordion defaultActiveKey={accordionData[0].key}>
                {accordionData.map(item => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.header}</Accordion.Header>
                    <Accordion.Body>{item.body}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HelpCenter;

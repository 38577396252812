import React from 'react';
import { Button, Col, Container, Row} from 'react-bootstrap';
const InnerHeader = ({ backgroundImage, title, tagline, content, button }) => {
  const componentStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#0A6CC7',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
  };
 
  return (
    <header className="innerHeader" style={componentStyle}>
        <Container fluid>
          <Row>
            <Container className="text-center">
              <h1>{title}</h1>
              <h4>{tagline}</h4>
              <Col>
                <Row className='justify-content-center'>
                  <Col xs={12} md={6}>
                    <p>{content}</p>
                  </Col>
                </Row>
                <Button href="/FreeDemo" variant="warning">Start Free Trail</Button>
              </Col>
            </Container>
          </Row>
        </Container>
    </header>
  );
};

export default InnerHeader;

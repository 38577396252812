import React from 'react'
import Header from "../InnerHeader";
import headerbg from '../../img/retailstorebg.jpg'
import BusinessType from '../../components/BusinessTypes/BusinessTypes';
const Retail = () => {
  const content = (
    <b className="text-warning">
      Jadeed Munshi's
    </b>
  );
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Retail Stores Management Software" 
        tagline="Grow Your Any Retail Business with Jadeed Munshi"
        content={
          <span>
            {content} Software can completely automate your any kind of Retail Business structure. Sell everything on customer’s need, scale comfortably, and make smart choices with actual-time reporting
          </span>
        }
      />
      <BusinessType/>
    </>
  );
};
export default Retail
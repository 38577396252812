import React from 'react'
import Header from "../InnerHeader";
import headerbg from '../../img/shoesstore.jpg'
import BusinessType from '../../components/BusinessTypes/BusinessTypes';
const Shoe = () => {
  const content = (
    <b className="text-warning">
      Jadeed Munshi's
    </b>
  );
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Shoe Stores Software" 
        tagline="Elevate your shoe store business with Our Point of Sale Software." 
        content={
          <span>{content} Software can comprehensively automate the structure of any type of shoe store. Tailor your sales to customer needs, scale effortlessly, and make informed decisions with real-time reporting.
          </span>
        }
      />
      <BusinessType/>
    </>
  );
};

export default Shoe

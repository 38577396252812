import React from "react";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import TestimonialsSlide from "./TestimonialsSlide";
const Testimonial = () => {
  return (
    <>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={12} md={6}>
            <div className="main-heading main-heading-left">
              <h1>
                People <span>Feedback</span>
              </h1>
              <div className="bottom-border">
                <span className="blue"></span>
                <span className="yellow"></span>
              </div>
            </div>
            <p>
            Many of our client relationships have blossomed into long-term partnerships. This is a testament to the trust our clients place in us, knowing that we are dedicated to their success not just in the short term, but for the duration of our collaborative journey.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <TestimonialsSlide/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Testimonial;

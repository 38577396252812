import React from 'react'
import Header from "../InnerHeader";
import headerbg from '../../img/bookstore.jpg'
import BusinessType from '../../components/BusinessTypes/BusinessTypes';
const Book = () => {
  const content = (
    <b className="text-warning">
      Jadeed Munshi's
    </b>
  );
  return (
    <>
      <Header 
        backgroundImage={headerbg} 
        title="Book Stores Software" 
        tagline="Full-Featured Point of Sale Software for Bookstores" 
        content={
          <span>
            Are you in search of effective and user-friendly point-of-sale (POS) software tailored for bookstores? {content} presents a thorough POS software solution designed to simplify inventory management, track sales, and enhance customer engagement. Discover our array of features and elevate your bookstore operations to new heights.
          </span>
        }
      />
      <BusinessType/>
    </>
  );
};



export default Book

import { useState, React } from 'react';
import {Tab, Tabs, Card, Row, Container, Col, Button} from "react-bootstrap";
const MonthlyPricingCard = ({ plans }) => {
  return (
    <>
      <Container>
        <Row>
          {plans.map((plan, index) => (
            <Col xs={12} md={3}>
                <Card  key={index} className="pricing-card text-center">
                  <Card.Body>
                  <h4>{plan.payFor}</h4>
                  <h2>{plan.name}</h2>
                  </Card.Body>
                  <Card.Text className='pricingFeatures'>{plan.features}</Card.Text>
                  <Card.Body>
                    <h2><small>PKR. </small>{plan.price}</h2>
                    <h5>You Saved Rs. {plan.save}</h5>
                    <Button href='/Contact' variant="primary" className='mb-4'>Buy Now</Button>
                    <Card.Text><h6><a href='/Contact'>{plan.freetrail}</a></h6></Card.Text>
                  </Card.Body>
                </Card >
            </Col>
          ))}
        </Row>
      </Container>
      
    </>
  );
};
const YearlyPricingCard = ({ plans }) => {
  return (
    <>
      <Container>
        <Row>
          {plans.map((plan, index) => (
            <Col xs={12} md={3}>
                <Card  key={index} className="pricing-card text-center">
                  <Card.Body>
                  <h4>{plan.payFor}</h4>
                  <h2>{plan.name}</h2>
                  </Card.Body>
                  <Card.Text className='pricingFeatures'>{plan.features}</Card.Text>
                  <Card.Body>
                    <h2><small>PKR. </small>{plan.price}</h2>
                    <h5>You Saved Rs. {plan.save}</h5>
                    <Button href='/Contact' variant="primary" className='mb-4'>Buy Now</Button>
                    <Card.Text><h6><a href='/Contact'>{plan.freetrail}</a></h6></Card.Text>
                  </Card.Body>
                </Card >
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

// Example usage of the PricingCard component
const App = () => {
  const monthlyPricingPlans = [
    {
      name: 'One User',
      payFor: 'Pay For',
      price: '2700/m',
      // features: ['Feature 1', 'Feature 2', 'Feature 3'],
      features: 'All Features Included',
      save: '13,884',
      freetrail: 'Start Free Trial Now',
    },
    {
      name: 'Three User',
      payFor: 'Pay For',
      price: '4500/m',
      features: 'All Features Included',
      save: '23,143',
      freetrail: 'Start Free Trial Now',
    },
    {
      name: 'Five User',
      payFor: 'Pay For',
      price: '6300/m',
      features: 'All Features Included',
      save: '32,057',
      freetrail: 'Start Free Trial Now',
    },
    {
      name: 'Ten User',
      payFor: 'Pay For',
      price: '10800/m',
      features: 'All Features Included',
      save: '55,543',
      freetrail: 'Start Free Trial Now',
    },
  ];
  const yearlyPricingPlans = [
    {
      name: 'One User',
      payFor: 'Pay For',
      price: '3857/m',
      // features: ['Feature 1', 'Feature 2', 'Feature 3'],
      features: 'All Features Included',
      save: '13,884',
      freetrail: 'Start Free Trial Now',
    },
    {
      name: 'Three User',
      payFor: 'Pay For',
      price: '6,429/m',
      features: 'All Features Included',
      save: '23,143',
      freetrail: 'Start Free Trial Now',
    },
    {
      name: 'Five User',
      payFor: 'Pay For',
      price: '9,000/m',
      features: 'All Features Included',
      save: '32,057',
      freetrail: 'Start Free Trial Now',
    },
    {
      name: 'Ten User',
      payFor: 'Pay For',
      price: '15429/m',
      features: 'All Features Included',
      save: '55,543',
      freetrail: 'Start Free Trial Now',
    },
  ];
  const [key, setKey] = useState('monthly');
  return (
    <>
    <Container>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-4 justify-content-center"
      >
        <Tab eventKey="monthly" title="Monthly">
          <MonthlyPricingCard plans={monthlyPricingPlans} />
        </Tab>
        <Tab eventKey="yearly" title="Yearly">
          <YearlyPricingCard plans={yearlyPricingPlans} />
        </Tab>
      </Tabs>
      </Container>
    </>
  );
};

export default App;

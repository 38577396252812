import React from "react";
import { Row, Container, Image } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rehmatfood from "../img/clients/rehmatfood.jpg";
import barkatfood from "../img/clients/barkatfood.jpg";
import idealprinter from "../img/clients/idealprinter.jpg";
import finad from "../img/clients/finad.jpg";
import hondabikemoters from "../img/clients/hondabikemoters.jpg";
import alipackages from "../img/clients/alipackages.jpg";
import hasampaper from "../img/clients/hasampaper.jpg";
import paktentage from "../img/clients/paktentage.jpg";
import citypark from "../img/clients/citypark.jpg";
import chanabpark from "../img/clients/chanabpark.jpg";
import humerafabrics from "../img/clients/humerafabrics.jpg";
import zamanpvc from "../img/clients/zamanpvc.jpg";
import sifcoengineering from "../img/clients/sifcoengineering.jpg";

const ClientCarousel = () => {
  const clientImages = [
    rehmatfood,
    barkatfood,
    idealprinter,
    finad,
    hondabikemoters,
    alipackages,
    hasampaper,
    paktentage,
    citypark,
    chanabpark,
    humerafabrics,
    zamanpvc,
    sifcoengineering,
  ];

  const slickSettings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section>
      <Container>
        <Row>
          <div className="main-heading text-center">
            <h1>Our Valuable <span>Clients</span></h1>
            <div className="bottom-border">
              <span className="blue"></span>
              <span className="yellow"></span>
            </div>
          </div>
        </Row>
        <Slider className="customer-logos" {...slickSettings}>
          {clientImages.map((image, index) => (
            <div key={index} className="slide">
              <Image src={image} thumbnail />
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  );
};

export default ClientCarousel;

import React from 'react'
import Header from "./InnerHeader";
import {Button, Form, Col, Row, Container, Card, CardBody } from 'react-bootstrap';
import headerbg from '../img/innerheader.jpg'
import ContactForm from './ContactForm';
const FreeDemo = () => {
  return (
    <>
      <Header backgroundImage={headerbg} title="Free Demo Trail" />
      <section>
        <Container>
          <Row className='justify-content-center'>
            <Col xs={12} sm={10} md={8}>
              <Card> <CardBody><ContactForm/></CardBody></Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default FreeDemo
